import { Box, Typography } from "@mui/material";
import React from "react";

export default function FontPage() {
  return (
    <div>
      <Box>
        <Typography variant="h3">
          {" "}
          Extend Equipment lifespan with our advanced Scaling And Corrosion
          Inhibitors!
        </Typography>

        <Typography variant="h4">
          {" "}
          Headline 2 SF pro display Bold 40 Letter spacing 1.5%
        </Typography>

        <Typography variant="subtitle1">
          {" "}
          Title 1 SF pro display Bold 29 Letter spacing 1%
        </Typography>

        <br />
        <Typography variant="callout">
          {" "}
          Callout 1 SF pro display Medium 20 letter spacing 0.71px
        </Typography>
        <Typography variant="body1">
          {" "}
          Body 1 Sf pro display Semibold 20 Letter Spacing 1.5%
        </Typography>
        <br />
        <Typography variant="body2">
          {" "}
          Body 2 SF pro display Semibold 20 Letter spacing 0.5px
        </Typography>

        <br />
        <Typography variant="body3">
          {" "}
          Body 3 Sf pro display Semibold 20 Letter spacing 1.5%
        </Typography>
        <br />
        <Typography variant="body4">
          {" "}
          Body 4 Sf pro display Medium 20 Letter spacing 1.5%
        </Typography>
        <br />
        <Typography variant="level">
          {" "}
          text field Sf pro display Regular 20 Letter Spacing 4%
        </Typography>
        <br />

        <Typography variant="button2">
          {" "}
          Button 2 SF pro Display Semibold 13 Letter spacing 1.25%
        </Typography>
        <br />
        <Typography variant="button3">
          {" "}
          Button 3 Sf pro display Semibold 16 Letter spacing 1%
        </Typography>
        <br />
      </Box>
    </div>
  );
}
