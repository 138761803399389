// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyChO0btDYf39SfE7qrY_EBXL861_RDgY20",
  authDomain: "pdwt-chemicals.firebaseapp.com",
  projectId: "pdwt-chemicals",
  storageBucket: "pdwt-chemicals.appspot.com",
  messagingSenderId: "25386551836",
  appId: "1:25386551836:web:3cca860ae086d2d084add9",
  measurementId: "G-YER26TNGF2"
};

// const firebaseConfig = {
//   apiKey: "AIzaSyA5gMtXDuoTmUIOxJd4elt2A04aFJMqMvs",
//   authDomain: "pwd-chemicals.firebaseapp.com",
//   projectId: "pwd-chemicals",
//   storageBucket: "pwd-chemicals.appspot.com",
//   messagingSenderId: "998603441484",
//   appId: "1:998603441484:web:4040ae0df306bb960c3be9",
//   measurementId: "G-1P23QXMEKZ"
// };


// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize Firestore
const db = firebase.firestore();

// Initialize Authentication
const auth = firebase.auth();

export { firebase, db, auth };