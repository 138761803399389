import product1 from "../../../utilities/Icons/mobilePro/Pro1.png";


import product2 from "../../../utilities/Icons/mobilePro/Pro 2.png";
import product3 from "../../../utilities/Icons/mobilePro/Pro 3.png";
import product4 from "../../../utilities/Icons/mobilePro/Pro 4.png";
import product5 from "../../../utilities/Icons/mobilePro/Pro 5.png";
import product6 from "../../../utilities/Icons/mobilePro/Pro 6.png";
import product7 from "../../../utilities/Icons/mobilePro/Pro 7.png";

const dataArr = [
  {
    list: [
      "Prevent scale, corrosion, fouling, and biofouling for maximum efficiency.",
      "Tailor-made formulations to suit specific needs.",
      "Single-product treatment programs for ease of use.",
      "Effective control of scale, corrosion, and biofouling.",
    ],
    name: "Cooling Tower Chemicals",
    img: product1,
  },

  {
    list: [
      "Ensure trouble-free and safe boiler operation with our effective formulations.",
      "Prevent scale and corrosion with multipurpose formulations.",
      "Includes polymeric sludge conditioners, oxygen scavengers, corrosion control agents, and scale inhibitors.",
    ],
    name: "Boiler Chemicals",
    img: product2,
  },

  {
    list: [
      "Tackle persistent issues like mineral deposits, scale buildup, and corrosion head-on.",
      "Prioritize efficiency, safety, and environmental responsibility for peak HVAC performance.",
    ],
    name: "AHU Descaling Chemicals",
    img: product3,
  },

  {
    list: [
      "Combat scale build-up and corrosion in offline and online systems.",
      "Minimize downtime and inefficiencies for peak equipment performance and longevity.",
    ],
    name: "Offline/Online Descaling Chemicals",
    img: product4,
  },

  {
    list: [
      "Enhance performance and lifespan of chiller systems.",
      "Combat microbial growth, scale build-up, and corrosion.",
      "Ensure reliable cooling performance and minimal maintenance requirements.",
    ],
    name: "Chiller Tower Chemicals",
    img: product5,
  },

  {
    list: [
      "Optimize combustion and reduce operational problems with our additives.",
      "Achieve complete combustion, control corrosion deposits, reduce emissions, and inhibit fouling for efficient energy savings.",
      "Includes scale deposit control, combustion catalysts, and furnace cleaning compounds.",
    ],
    name: "Fireside Additives",
    img: product6,
  },

  {
    list: [
      "Maintain optimal membrane performance with our antiscalant and cleaning chemicals.",
      "Prevent fouling and scaling for reliable R.O. system operation.",
    ],
    name: "R.O. Antiscalants and Cleaners",
    img: product7,
  },
];

export { dataArr };
