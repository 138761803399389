const tableData = [
  {
    name: "Test one",
    ndis: 78866,
    gender: "Male",
    age: 70,
    date_of_last_assessment: "23-09-2024",
    date_of_last_report: "26-09-2024",
  },
  {
    name: "Sunny",
    ndis: 78866,
    gender: "Female",
    age: 25,
    date_of_last_assessment: "23-09-2024",
    date_of_last_report: "26-09-2024",
  },
];

export { tableData };
